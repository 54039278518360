import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Logo from './components/Logo/Logo';
import DataCardPanel from './components/DataCardPanel/DataCardPanel';

function App() {

  return (
    <div className="App">
      <Logo />
      {DataCardPanel}
    </div>
  );
}

export default App;
