import './Logo.css';
import logo from './logo.png';

const Logo = () => {
	return (
		<div className="">
            <img className='App-logo' style={{paddingTop: '5px'}} alt="Logo" src={logo} />
		</div>
	);
}

export default Logo;