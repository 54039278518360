// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        title: "Burger Bacon",
        ingredientes: "bacon, chedar, tomate, lechuga",
        price: "$ 136",
        coverImg: "dish.png"
    },
    {
        id: 2,
        title: "Chesee Burger",
        ingredientes: "chedar, tomate, lechuga",
        price: "$ 146",
        coverImg: "dish.png"
    },
    {
        id: 3,
        title: "Chesee Burger",
        ingredientes: "chedar, tomate, lechuga",
        price: "$ 146",
        coverImg: "dish.png"
    }
]