import React from "react";
import "./CardPanel.css";

const CardPanel = (props) => {
    return (
        <div className="card-panel recipe white row">
            <img src={require(`../../images/${props.coverImg}`)} alt="recipe thumb" />
            <div className="recipe-details">
                <div className="recipe-title">{props.title}</div>
                <div className="recipe-ingredients">{props.ingredientes}</div>
            </div>
            <div className="recipe-price">{props.price}</div>
        </div>
    )
}

export default CardPanel;