import data from './data';
import CardPanel from './../CardPanel/CardPanel';
console.log(data);

const DataCardPanel = data.map(item => {
    return (
        <CardPanel
            key={item.id}
            {...item}
        />
    )
})

export default DataCardPanel;